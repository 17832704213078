<template>
  <div class="pa-6">
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="h-100" outlined flat>
          <v-card-text class="pa-4">
            <h3 class="primary--text">{{ $t('chatSettings.settings1.title') }}</h3>
            <v-radio-group v-model="form.openWindowInside" @change="update">
              <v-row>
                <v-col cols="12" class="d-flex justify-space-between align-center">
                  <v-radio :value="true">
                    <template #label>
                      <span>{{ $t('chatSettings.settings1.option1') }}</span>
                    </template>
                  </v-radio>
                  <v-img max-width="80" contain src="@/assets/images/settings/settings1.png" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-space-between align-center">
                  <v-radio :value="false">
                    <template #label>
                      <span>{{ $t('chatSettings.settings1.option2') }}</span>
                    </template>
                  </v-radio>
                  <v-img max-width="80" contain src="@/assets/images/settings/settings2.png" />
                </v-col>
              </v-row>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="h-100" outlined flat>
          <v-card-text class="pa-4">
            <h3 class="primary--text">{{ $t('chatSettings.settings2.title') }}</h3>
            <v-radio-group v-model="form.newMessageOnlyOnList" @change="update">
              <v-row>
                <v-col cols="12" class="d-flex justify-space-between align-center">
                  <v-radio :value="true">
                    <template #label>
                      <span>{{ $t('chatSettings.settings2.option1') }}</span>
                    </template>
                  </v-radio>
                  <v-img max-width="80" contain :src="require('@/assets/images/settings/settings3.png')" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-space-between align-center">
                  <v-radio :value="false">
                    <template #label>
                      <span>{{ $t('chatSettings.settings2.option2') }}</span>
                    </template>
                  </v-radio>
                  <v-img max-width="80" contain src="@/assets/images/settings/settings4.png" />
                </v-col>
              </v-row>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="h-100" outlined flat>
          <v-card-text class="pa-4">
            <h3 class="primary--text">
              {{ $t('chatSettings.settings3.title') }}
            </h3>
            <v-radio-group v-model="form.openFromRightToLeft" @change="update">
              <v-row>
                <v-col cols="12" class="d-flex justify-space-between align-center">
                  <v-radio :value="true">
                    <template #label>
                      <span>{{ $t('chatSettings.settings3.option1') }}</span>
                    </template>
                  </v-radio>
                  <v-img max-width="80" contain src="@/assets/images/settings/settings5.png" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-space-between align-center">
                  <v-radio :value="false">
                    <template #label>
                      <span>{{ $t('chatSettings.settings3.option2') }}</span>
                    </template>
                  </v-radio>
                  <v-img max-width="80" contain src="@/assets/images/settings/settings6.png" />
                </v-col>
              </v-row>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="h-100" outlined flat>
          <v-card-text class="pa-4">
            <h3 class="primary--text mb-4">
              {{ $t('chatSettings.settings4.title') }}
            </h3>
            <v-radio-group v-model="form.pinToRight" @change="update">
              <v-row>
                <v-col cols="12" class="d-flex justify-space-between align-center">
                  <v-radio :value="true">
                    <template #label>
                      <span>{{ $t('chatSettings.settings4.option1') }}</span>
                    </template>
                  </v-radio>
                  <v-img max-width="80" contain src="@/assets/images/settings/settings7.png" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-space-between align-center">
                  <v-radio :value="false">
                    <template #label>
                      <span>{{ $t('chatSettings.settings4.option2') }}</span>
                    </template>
                  </v-radio>
                  <v-img max-width="80" contain src="@/assets/images/settings/settings8.png" />
                </v-col>
              </v-row>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Service from '@/services'

export default {
  name: 'Layout',
  data () {
    return {
      form: {
        openWindowInside: false,
        newMessageOnlyOnList: false,
        openFromRightToLeft: false,
        pinToRight: false
      }
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      const { data } = await Service.get('Chat/GetSettings')
      this.form = data
    },
    async update () {
      await Service.put('Chat/UpdateSettings', this.form)
      await this.$store.dispatch('chat/fetchSettings')
    }
  }
}
</script>
